<template>
  <div id="ai-services_vca_video-classification">
    <service-template
      :run="run"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :run-disabled="currentExample === null"
      :icon="require('@/assets/images/icons/services/header/QUA.svg')"
      type="video"
      service="vca"
    >
      <template v-slot:header>
        {{ $t('services.videoQualityEstimation.header') }}
      </template>
      <template v-slot:left>
        <carousel
          :step="1"
          :title="$t('services.carousel.videoTitle')"
          type="video"
        >
          <span
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            class="example"
            @click="() => onExampleClick(index)"
          >
            <video
              :src="`${example}#t=0.01`"
              muted
              preload="metadata"
              class="example"
            />
          </span>
        </carousel>
        <upload
          file-type="video"
          :file-size="2"
          :example-file="examples[currentExample]"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.videoQualityEstimation.header')"
          :loading="loading"
        >
          <div
            v-if="result"
            class="result"
          >
            <img
              :src="resultIcon"
              :alt="
                result.qualified
                  ? $t('services.videoQualityEstimation.ok')
                  : $t('services.videoQualityEstimation.warning')
              "
              class="result-icon"
            >
            <div class="result-text">
              {{ $t('services.videoQualityEstimation.qualified') }}
              <span :class="result.qualified ? 'ok' : 'warning'">
                {{
                  result.qualified
                    ? $t('services.videoQualityEstimation.ok')
                    : $t('services.videoQualityEstimation.warning')
                }}
              </span>
            </div>
            <div
              v-if="rejectReasons.length > 0"
              class="reject-reasons"
            >
              <div
                v-for="(reason, index) in rejectReasons"
                :key="index"
                class="reason"
              >
                {{ reason }}
              </div>
            </div>
          </div>
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import QualityOk from '@/assets/images/icons/services/result/aiu/quality-ok.svg';
import QualityWarning from '@/assets/images/icons/services/result/aiu/quality-warning.svg';
import Upload from '@/components/Upload/Index.vue';

export default {
  name: 'VideoQualityEstimation',
  components: {
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
  }),
  computed: {
    examples() {
      return [
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/blurred%20video%202.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/high%20qual%20video.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/1.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/2.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/3.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/4.mp4`,
        `${this.$consts.config.CDN.VIDEOS_CDN_URL}/input/vqe/5.mp4`,
      ];
    },
    resultIcon() {
      if (this.result) {
        return this.result.qualified ? QualityOk : QualityWarning;
      }
      return null;
    },
    rejectReasons() {
      if (this.result && !this.result.qualified) {
        return Object.values(this.result.results)
          .map((el) => el.trim());
      }
      return [];
    },
  },
  watch: {
    currentExample() {
      this.result = null;
    },
  },
  methods: {
    run() {
      this.loading = true;
      return this.$api.vca.videoEstimation.qualityEstimation(this.examples[this.currentExample]);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      this.currentExample = index;
    },
    onUploadRemove() {
      this.result = null;
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
  },
};
</script>

<style lang="scss" scoped>
  #ai-services_vca_video-classification {
    background: $background-secondary;

    .example {
      height: 100%;
      cursor: pointer;
    }

    /deep/ .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      .result-text {
        margin-top: 0.16rem;
        margin-bottom: 0.2rem;
        color: $text-gray;
        font-weight: 500;
        font-size: 0.16rem;

        .ok {
          font-weight: 700;
          color: $text-success;
        }

        .warning {
          font-weight: 700;
          color: $text-error;
        }
      }

      .reject-reasons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        margin: -0.05rem;

        .reason {
          margin: 0.05rem;
          padding: 0.1rem 0.16rem;
          border-radius: 0.32rem;
          color: $text-error;
          background-color: rgba($text-error, 0.1);
        }
      }
    }
  }
</style>
